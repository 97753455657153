export const STATUS_DRAFT = "DRAFT";
export const STATUS_PENDING = "PENDING";
export const STATUS_RUNNING = "RUNNING";
export const STATUS_COMPLETED = "COMPLETED";
export const STATUS_CANCELED = "CANCELED";

export const STATUS_CREATED = "CREATED";

export const STATUS_UPDATED = "UPDATED";

export const STATUS_DELETED = "DELETED";
