<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ importation.name }}</span>
    </div>

    <div class="row">
      <div class="col-9">
        <dl class="row" v-if="importation.linkedObject">
          <dt class="col-sm-4">{{ $t("COMMON.SUPPLIER") }}</dt>
          <dd class="col-sm-8">
            <supplier :supplier="importation.linkedObject" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("IMPORTS.FILE") }}</dt>
          <dd class="col-sm-8">
            <a :href="importation.file_url" target="_blank">
              {{ importation.file_url }}
            </a>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("IMPORTS.IMPORT_NAME") }}</dt>
          <dd class="col-sm-8">
            {{ importation.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("IMPORTS.IDENTIFIER") }}</dt>
          <dd class="col-sm-8">
            {{ importation.identifier.model_field }} =>
            {{ importation.identifier.csv_field }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="importation.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="importation.created_at">
            {{ $timeZoneDateFormat(importation.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="importation.updated_at">
            {{ $timeZoneDateFormat(importation.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>

    <el-collapse class="row mt-5">
      <el-collapse-item name="1" class="col-12 px-0 mb-4">
        <template #title>
          <h3>{{ $t("IMPORTS.IMPORT_PREVIEW_DATA") }}</h3>
        </template>
        <div>
          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="pagedRows"
          >
            <el-table-column
              v-for="(csvCol, objCol) in importation.mapping"
              :key="csvCol"
              :prop="csvCol"
              :label="objCol"
            />
          </el-table>
          <el-pagination
            layout="prev, pager, next"
            :total="rows.length"
            @current-change="
              (val) => {
                page = val;
              }
            "
          ></el-pagination>
        </div>
      </el-collapse-item>
      <el-collapse-item name="2" class="col-12 px-0">
        <template #title>
          <h3>{{ $t("IMPORTS.CSV_PREVIEW_DATA") }}</h3>
        </template>
        <div>
          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="pagedRows"
          >
            <el-table-column
              v-for="field in csvFields"
              :key="field"
              :prop="field"
              :label="field"
              min-width="120px"
            />
          </el-table>
          <el-pagination
            layout="prev, pager, next"
            :total="rows.length"
            @current-change="
              (val) => {
                page = val;
              }
            "
          ></el-pagination>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import Papa from "papaparse";
import {
  Collapse,
  CollapseItem,
  Pagination,
  Table,
  TableColumn,
} from "element-ui";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },

  props: ["importation"],

  data() {
    return {
      previewRows: [],
      rows: [],
      page: 1,
      pageSize: 10,
      csvFields: [],
    };
  },

  created() {
    this.getPreviewRows();
  },

  methods: {
    getPreviewRows() {
      if (this.importation.file_url) {
        Papa.parse(this.importation.file_url, {
          download: true,
          skipEmptyLines: true,
          header: true,
          complete: (results) => {
            this.rows = results.data;
            this.csvFields = results.meta.fields;
          },
        });
      }
    },
  },

  computed: {
    pagedRows() {
      return this.rows.slice(
        this.pageSize * this.page - this.pageSize,
        this.pageSize * this.page
      );
    },
  },

  watch: {
    importation(importation) {
      this.getPreviewRows();
    },
  },
};
</script>
