<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-8">
              <base-button
                v-if="
                  $currentUserCan($permissions.PERM_DELETE_IMPORTS) &&
                  importation.status === STATUS_DRAFT
                "
                type="danger"
                icon
                size="sm"
                :title="$t('COMMON.DELETE')"
                @click="deleteImport()"
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-trash"></i>
                </span>
              </base-button>

              <template v-if="!importation.linkedObject">
                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_CREATE_IMPORTS) &&
                    importation.status === STATUS_DRAFT
                  "
                  type="warning"
                  icon
                  size="sm"
                  @click="runDry()"
                  :title="$t('IMPORTS.RUN_DRY_IMPORT')"
                >
                  <span class="btn-inner--icon">
                    {{ $t("IMPORTS.RUN_DRY_IMPORT") }}
                    <i class="fas fa-rocket"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_CREATE_IMPORTS) &&
                    importation.status === STATUS_DRAFT
                  "
                  type="success"
                  icon
                  size="sm"
                  @click="run()"
                  :title="$t('IMPORTS.RUN_IMPORT')"
                >
                  <span class="btn-inner--icon">
                    {{ $t("IMPORTS.RUN_IMPORT") }}
                    <i class="fas fa-rocket"></i>
                  </span>
                </base-button>
              </template>

              <template v-else>
                <base-button
                  v-if="$currentUserCan($permissions.PERM_CREATE_IMPORTS)"
                  type="warning"
                  icon
                  size="sm"
                  @click="drySynchronizeProduct()"
                >
                  <span class="btn-inner--icon">
                    {{ $t("IMPORTS.RUN_DRY_SYNC") }}
                    <i class="fas fa-sync-alt"></i>
                  </span>
                </base-button>
                <base-button
                  v-if="$currentUserCan($permissions.PERM_CREATE_IMPORTS)"
                  type="success"
                  icon
                  size="sm"
                  @click="synchronizeProduct()"
                >
                  <span class="btn-inner--icon">
                    {{ $t("PRODUCTS.SYNC_PRODUCT") }}
                    <i class="fas fa-sync-alt"></i>
                  </span>
                </base-button>
              </template>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>

              <notification-subscription
                :objectType="'imports'"
                :objectId="importation.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap tabs-wrapper"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="false">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <import-view-global :importation="importation" />
                </tab-pane>

                <tab-pane
                  v-if="importation.status === STATUS_COMPLETED"
                  title="results"
                  id="2"
                  :active="false"
                >
                  <span slot="title">
                    <i class="ni ni-collection" />
                    {{ $t("IMPORTS.RESULTS") }}
                  </span>
                  <import-view-results :importation="importation" />
                </tab-pane>

                <tab-pane
                  v-if="importation.status === STATUS_COMPLETED"
                  title="importedItems"
                  id="3"
                  :active="false"
                >
                  <span slot="title">
                    <i class="ni ni-cloud-download-95" />
                    {{ $t("IMPORTS.IMPORTED_ITEMS") }}
                  </span>
                  <import-view-imported-items :importation="importation" />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <import-view-logs :importation="importation" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
    <modal
      :show.sync="showResultsModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <import-results :hasStatus="hasStatus" :importResults="importResults" />
    </modal>
  </div>
</template>

<script>
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Link,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { TabPane, Tabs } from "@/components";
import { STATUS_DRAFT, STATUS_COMPLETED } from "@/constants/imports";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ImportViewGlobal from "@/views/Pages/AdminModule/ImportModule/Partials/ImportViewGlobal.vue";
import ImportViewLogs from "@/views/Pages/AdminModule/ImportModule/Partials/ImportViewLogs.vue";
import ImportViewResults from "@/views/Pages/AdminModule/ImportModule/Partials/ImportViewResults.vue";
import ImportResults from "@/views/Pages/AdminModule/ImportModule/Partials/ImportResults.vue";
import ImportViewImportedItems from "@/views/Pages/AdminModule/ImportModule/Partials/ImportViewImportedItems.vue";
import defaultImport from "./defaultImport";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Link.name]: Link,
    Tabs,
    TabPane,
    NotificationSubscription,
    ImportViewGlobal,
    ImportViewLogs,
    ImportResults,
    ImportViewResults,
    ImportViewImportedItems,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      importation: cloneDeep(defaultImport),
      id: this.$route.params.id,
      showResultsModal: false,
      importResults: [],
      hasStatus: false,
      STATUS_DRAFT: STATUS_DRAFT,
      STATUS_COMPLETED: STATUS_COMPLETED,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("imports/get", this.id);
        this.importation = this.$store.getters["imports/import"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async runDry() {
      const confirmation = await swal.fire({
        title: this.$t("IMPORTS.RUN_DRY_IMPORT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      if (!confirmation.value) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const importation = await this.$store.dispatch(
          "imports/runDry",
          this.id
        );
        this.importResults = importation.results;
        this.get();
        swal.close();
        this.showResultsModal = true;
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async synchronizeProduct() {
      const confirmation = await swal.fire({
        title: this.$t("PRODUCTS.RUN_SYNCHRONIZE_PRODUCTS"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      if (!confirmation.value) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const importation = await this.$store.dispatch(
          "imports/runProductSynchronization",
          this.id
        );
        this.importResults = importation.results;
        this.hasStatus = true;
        this.get();
        swal.close();
        this.showResultsModal = true;
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async drySynchronizeProduct() {
      const confirmation = await swal.fire({
        title: this.$t("PRODUCTS.RUN_DRY_SYNCHRONIZE_PRODUCTS"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      if (!confirmation.value) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const importation = await this.$store.dispatch(
          "imports/runDryProductSynchronization",
          this.id
        );
        this.importResults = importation.results;
        this.hasStatus = true;
        this.get();
        swal.close();
        this.showResultsModal = true;
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async run() {
      const confirmation = await swal.fire({
        title: this.$t("IMPORTS.RUN_IMPORT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      if (!confirmation.value) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const importation = await this.$store.dispatch("imports/run", this.id);
        this.importResults = importation.results;
        this.get();
        swal.close();
        this.showResultsModal = true;
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteImport() {
      const confirmation = await swal.fire({
        title: this.$t("IMPORTS.DELETE_THIS_IMPORT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("imports/destroy", this.id);
          this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("IMPORTS.IMPORT_DELETED"),
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    goBack() {
      this.$router.push({ name: "List Imports" });
    },
  },

  computed: {},
};
</script>
