<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${importation.name}` }} - {{ $t("IMPORTS.RESULTS") }}
      </span>
    </div>

    <import-results
      :hasStatus="importation.linkedObject"
      :importResults="importation.results"
    />
  </div>
</template>

<script>
import ImportResults from "./ImportResults";

export default {
  components: {
    ImportResults,
  },

  props: ["importation"],

  watch: {},
};
</script>
