<template>
  <div>
    <h2>{{ $t("IMPORTS.RESULTS_ON_X_LINES", { linesCount: totalCount }) }}</h2>
    <el-collapse class="">
      <el-collapse-item name="1" class="">
        <template #title>
          <h3>
            {{ $t("IMPORTS.SUCCESSES") }}
            <badge type="success">{{ successes.length }}</badge>
          </h3>
        </template>
        <div>
          <el-table :data="successesPagedRows">
            <el-table-column
              prop="line"
              :label="$t('IMPORTS.LINE')"
              width="150px"
            >
              <template v-slot="{ row }">
                {{ $t("IMPORTS.X_ROW", { line: row.line }) }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="hasStatus"
              prop="line"
              :label="$t('IMPORTS.STATUS')"
              width="150px"
            >
              <template v-slot="{ row }">
                <badge v-if="row.status === STATUS_DELETED" type="danger">{{
                  $t(`IMPORTS.${row.status}`)
                }}</badge>
                <badge v-if="row.status === STATUS_CREATED" type="success">{{
                  $t(`IMPORTS.${row.status}`)
                }}</badge>
                <badge v-if="row.status === STATUS_UPDATED" type="warning">{{
                  $t(`IMPORTS.${row.status}`)
                }}</badge>
              </template>
            </el-table-column>
            <el-table-column prop="data" :label="$t('IMPORTS.DATA')">
              <template v-slot="{ row }">
                {{ row.data }}
              </template>
            </el-table-column>
            <el-table-column
              prop="id"
              :label="$t('IMPORTS.MODEL_ID')"
              width="100px"
            >
              <template v-slot="{ row }">
                {{ row.id }}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            layout="prev, pager, next"
            :total="successes.length"
            @current-change="
              (val) => {
                successesPage = val;
              }
            "
          ></el-pagination>
        </div>
      </el-collapse-item>
      <el-collapse-item name="2" class="">
        <template #title>
          <h3>
            {{ $t("IMPORTS.FAILURES") }}
            <badge type="danger">{{ failures.length }}</badge>
          </h3>
        </template>
        <div>
          <el-table :data="failuresPagedRows">
            <el-table-column
              prop="line"
              :label="$t('IMPORTS.LINE')"
              width="150px"
            >
              <template v-slot="{ row }">
                {{ $t("IMPORTS.X_ROW", { line: row.line }) }}
              </template>
            </el-table-column>
            <el-table-column prop="data" :label="$t('IMPORTS.DATA')">
              <template v-slot="{ row }">
                {{ row.data }}
              </template>
            </el-table-column>
            <el-table-column prop="error" :label="$t('IMPORTS.ERROR')">
              <template v-slot="{ row }">
                {{ row.error }}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            layout="prev, pager, next"
            :total="failures.length"
            @current-change="
              (val) => {
                failuresPage = val;
              }
            "
          ></el-pagination>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {
  Collapse,
  CollapseItem,
  Pagination,
  Table,
  TableColumn,
} from "element-ui";

import {
  STATUS_CREATED,
  STATUS_DELETED,
  STATUS_UPDATED,
} from "@/constants/imports";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  name: "import-results",

  mixins: [],

  props: ["importResults", "hasStatus"],

  data() {
    return {
      failuresPage: 1,
      successesPage: 1,
      pageSize: 10,
      STATUS_DELETED,
      STATUS_CREATED,
      STATUS_UPDATED,
    };
  },

  computed: {
    successes() {
      return Object.values(this.importResults).filter((item) => item.success);
    },
    failures() {
      return Object.values(this.importResults).filter((item) => !item.success);
    },
    totalCount() {
      return Object.values(this.importResults).length;
    },
    successesPagedRows() {
      return this.successes.slice(
        this.pageSize * this.successesPage - this.pageSize,
        this.pageSize * this.successesPage
      );
    },
    failuresPagedRows() {
      return this.failures.slice(
        this.pageSize * this.failuresPage - this.pageSize,
        this.pageSize * this.failuresPage
      );
    },
  },

  methods: {},

  mounted() {},

  watch: {},
};
</script>
